import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components';
import { Header } from '../../components/theme';
import { COLOR_UMD_GOLD } from '../../utils/constants';

export const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const HeroContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 32px;
`;

export const HeroTitleStyle = styled.h1`
  font-size: 2rem;
  margin: 0;
  color: rgba(255, 255, 255, 1);

  @media (min-width: 414px) {
    font-size: 3rem;
  }
`;

export const HeroSubtitleStyle = styled.h2`
  padding: 1rem;
  font-size: 1rem;
  margin: 0;
  color: rgba(255, 255, 255, 1);

  @media (min-width: 414px) {
    font-size: 1.5rem;
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
`;

const HeroText = styled.p`
  color: white;
`;

const HeroLink = styled.a`
  margin-top: 16px;
  color: black;
  background-color: ${COLOR_UMD_GOLD};
  padding: 16px 32px;
  border: 1px solid;
  border-radius: 8px;
  text-decoration: none;
  :hover {
    background-color: ${COLOR_UMD_GOLD}80;
    text-decoration: none;
  }
`;

/**
 * The Group Photo needs to support several types of layouts:
 *  * mobile
 *  * tablet
 *  * laptop/desktop
 *  * extra-wide monitors
 *
 * The people in the group photo are filtered by the `groupPhoto.inGroupPhoto` property.
 * The people in the group photo are also sorted by priority, highest to lowest
 *
 * There are two queries that need to happen and then get zipped by the person's name.
 * Zipping is a transformation process where elements in two arrays get combined into a single
 * element in one array
 */
const Hero = function() {
  return (
    <Wrapper>
      <Header />
      <div style={{ display: "grid" }}>
        <StaticImage
          style={{
            gap: "1rem",
            gridArea: "1/1",
              // You can set a maximum height for the image, if you wish.
              // maxHeight: 600,
          }}
          layout="fullWidth"
          // This is a presentational image, so the alt should be an empty string
          alt=""
          src="../../images/mindlab-hero.png"
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "center",
            display: "grid",
          }}
        >
          {/* Any content here will be centered in the component */}
          <HeroContentWrapper>
            <HeroContent>
              <HeroTitleStyle>MIND Lab</HeroTitleStyle>
              <HeroSubtitleStyle>Maryland Information and Network Dynamics Lab</HeroSubtitleStyle>
            </HeroContent>
            <HeroContent>
              <HeroText>Interested in researching cutting edge solutions to exciting problems?</HeroText>
              <HeroLink
                href="https://docs.google.com/forms/d/e/1FAIpQLSd_wjOhFXW1Nb7oSlgCiWEUq6sTLCbZu7Z_u6xc8NFKw60pRw/viewform?usp=sf_link"
                target="_blank"
              >
                Sign Up Now!
              </HeroLink>
            </HeroContent>
          </HeroContentWrapper>
        </div>
      </div>
    </Wrapper>
  );
};

export default Hero;

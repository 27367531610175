import React from 'react';
import { StaticQuery, Link, navigate, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import {
  RightArrow,
  FeatureProjectContainer,
  FeaturedProjectHeaderContainerStyles,
  ResponsiveDesktopContainer,
  Details,
  ProjectCard,
  TagContainer,
  Tag,
  OneLiner,
  AlignRight,
  FeaturedProjectContents,
} from '../../styles';

const FeaturedProjects = () => {
  const featuredProjectsData = useStaticQuery(graphql`
    query featuredProjects {
      allMarkdownRemark(filter: {frontmatter: {featured: {eq: true}}}) {
        edges {
          node {
            html
            frontmatter {
              featured
              path
              hero {
                absolutePath
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
                }
              }
              tags
              oneLiner
              title
            }
            id
          }
        }
      }
    }
  `).allMarkdownRemark.edges;
  const projectCards = featuredProjectsData.map(projectData => {
    return <ProjectCard
        key={projectData.node.frontmatter.path}
      >
        <GatsbyImage image={projectData.node.frontmatter.hero.childImageSharp.gatsbyImageData} alt="" />
        <h2>{projectData.node.frontmatter.title}</h2>
        <TagContainer>
          {projectData.node.frontmatter.tags.map(tag => (
            <Tag key={tag}>
              {tag}
            </Tag>
          ))}
        </TagContainer>
        <OneLiner>{projectData.node.frontmatter.oneLiner}</OneLiner>
        <AlignRight>
          <Link to={projectData.node.frontmatter.path}>Click here for more {`>>`}</Link>
        </AlignRight>
      </ProjectCard>
  })

  const breathingTags = [ "machine learning", "sensors", "signals processing", "physiology" ];
  const buildingTags = [ "data modeling", "probability & statistics", "sensors" ];
  return (
    <FeatureProjectContainer id="featured-project-container">
      <FeaturedProjectContents id="featured-project-contents">
        <FeaturedProjectHeaderContainerStyles className="featured-project-header-container">
          <h1>Featured Projects</h1>

          <Link to="/projects">
            All Projects <RightArrow />
          </Link>
        </FeaturedProjectHeaderContainerStyles>

        <Details>
          <ResponsiveDesktopContainer>
            {projectCards}
          </ResponsiveDesktopContainer>
        </Details>
      </FeaturedProjectContents>
    </FeatureProjectContainer>
  );
};

export default FeaturedProjects;

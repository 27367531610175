import React from 'react';

import { Layout, SEO } from '../components/common';
import Hero from '../components/landing/Hero';
import LabIntro from '../components/landing/LabIntro';
import FeaturedProjects from '../components/landing/FeaturedProjects';
import Announcements from '../components/landing/Announcements';
import Collaborators from '../components/landing/Collaborators';

const BasicPage = function () {
  return (
    <Layout>
      <SEO />
      <Hero />
      <LabIntro />
      <FeaturedProjects />
      <Announcements />
      <Collaborators />
    </Layout>
  );
};

export default BasicPage;

import styled from 'styled-components';

export const CollaboratorsWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const CollaboratorsContent = styled.div`
  max-width: 1024px;
`;

export const CommercialCollaboratorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;
  gap: 2rem;
`;

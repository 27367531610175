import React from 'react';
import {
  Container,
  AnnouncementsWrapper,
  AnnouncementTitleSection,
  CalloutContainerStyle,
  CalloutStyle,
  CalloutTitleStyle,
  CalloutTextStyle,
} from '../../styles';

const Announcements = function () {
  return (
    <AnnouncementsWrapper as={Container}>
      <h1>Highlights</h1>

      <CalloutContainerStyle className="callout-container">
        <CalloutStyle className="callout">
          <AnnouncementTitleSection className="announcement-title-section">
            <CalloutTitleStyle>Professor Ashok Agrawala's 50th anniversary at UMD</CalloutTitleStyle>
          </AnnouncementTitleSection>
          <CalloutTextStyle>
            On May 13, the CS department celebrated Professor Agrawala's 50th anniversary at UMD! See{' '}
            <a href="https://www.cs.umd.edu/ashok50">here</a> for more details.
          </CalloutTextStyle>
        </CalloutStyle>

        <CalloutStyle className="callout">
          <AnnouncementTitleSection className="announcement-title-section">
            <CalloutTitleStyle>TIBCO Modathon Winners!</CalloutTitleStyle>
          </AnnouncementTitleSection>
          <CalloutTextStyle>
            MindLab Student Team win Second Place in TIBCO Modathon!{' '}
            <a href="https://twitter.com/umdcs/status/1410287373397762049">Click here to see the Tweet</a> and{' '}
            <a href="https://www.linkedin.com/posts/markwalterpalmer_datascience-data-machinelearning-activity-6813121990443053056-zhs5">
              Click here for the video demo!
            </a>
          </CalloutTextStyle>
        </CalloutStyle>

        <CalloutStyle className="callout">
          <AnnouncementTitleSection className="announcement-title-section">
            <CalloutTitleStyle>Additional Project Opportunities</CalloutTitleStyle>
          </AnnouncementTitleSection>
          <CalloutTextStyle>ExploreDigits and MindLab start a collaborative research program.</CalloutTextStyle>
        </CalloutStyle>
      </CalloutContainerStyle>
    </AnnouncementsWrapper>
  );
};

export default Announcements;

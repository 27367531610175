import React from 'react';

import { LabIntroContainerStyles, LabIntroDescriptionStyles, LabContentStyles } from '../../styles';

/*
  Responsiveness on the Intro page:
  * Use breakpoints for each device size
*/

const LabIntro = function() {
  return (
    <LabIntroContainerStyles id="lab-intro">
      <LabContentStyles id="intro-content">
        <LabIntroDescriptionStyles id="intro-section">
          <h1>Intro</h1>
          <p>
            Since its inception in 2001 the MIND Lab has been engaged in solving practical problems using current Computer
            Science knowledge when appropriate and developing new technologies as required. Fujitsu Limited established
            its east coast research lab next to the MIND Lab and became a founding partner when the lab was established.
            Since then the MIND Lab has collaborated with multiple small, medium, and large companies on a variety of
            problems.
          </p>
          <p>
            Research and development work at the MIND Lab have resulted in the graduation of 19 PhDs, 4 patents and
            numerous publications. The indoor location technique, Horus, developed in 2004 is considered a landmark work
            in this area.
          </p>
          <p>
            The MIND Lab continues to engage in close collaboration with companies in the framework of the Hub and push
            the state of the art in several areas while involving graduate, undergraduate, and high school students in the
            research and development.
          </p>
        </LabIntroDescriptionStyles>
      </LabContentStyles>
    </LabIntroContainerStyles>
  );
}

export default LabIntro;

import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import { CollaboratorsWrapper, CommercialCollaboratorContainer, CollaboratorsContent } from './collaborators.styles';

/*
  Same deal as the Group Photo Section and the People Page.
  Take the initial query, split into two parts to figure out the order and the `alt` text, and
  then recombine to display the logos
*/
const Collaborators = function() {
  return (
    <CollaboratorsWrapper id="collaborators">
      <CollaboratorsContent id="collaborators-content">
        <h1>Current Hub</h1>

        <CommercialCollaboratorContainer id="commercial-collaborators-container">
          <a target="_blank" rel="noopener noreferrer" href="https://www.nair-assoc.com/">
            <StaticImage height={75} alt="logo for Nair Associates" src="../../images/sponsors/nair-logo.png" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.exploredigits.com/">
            <StaticImage height={75} alt="logo for Explore Digits" src="../../images/sponsors/explore-digits-logo.png" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.spirehealth.com/">
            <StaticImage height={75} alt="logo for Spire Health" src="../../images/sponsors/spire-logo.png" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.technetenterprises.com/">
            <StaticImage height={75} alt="logo for TechNet Enterprises" src="../../images/sponsors/technet-logo.png" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.consciousbreathing.com/">
            <StaticImage height={75} alt="logo for Conscious Breathing" src="../../images/sponsors/conscious-breathing-logo.png" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.tibco.com/">
            <StaticImage height={75} alt="logo for Tibco Software" src="../../images/sponsors/tibco-logo.png" />
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.dell.com/">
            <StaticImage height={75} alt="logo for Dell Technologies" src="../../images/sponsors/dell-logo.png" />
          </a>
          <StaticImage height={75} alt="logo for Eloquia" src="../../images/sponsors/eloquia-logo.png" />
          <a target="_blank" rel="noopener noreferrer" href="https://sites.google.com/umd.edu/stopcovid/home">
            <StaticImage height={75} alt="logo for UMD School of Public Health" src="../../images/sponsors/umd-sph-logo.png" />
          </a>
        </CommercialCollaboratorContainer>
      </CollaboratorsContent>
    </CollaboratorsWrapper>
  );
};

export default Collaborators;
